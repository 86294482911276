<template>

  <v-row no-gutters>
    <v-col>

     
      <div v-if="status" id="data-list">
        <!-- app drawer -->
        <v-snackbars
          :objects.sync="snackbar"
          width="500"
          :timeout="1500"
          bottom
          right
          color="error"
          transition="slide-y-transition"
        >
          <template v-slot:default="{ message }">
            <div v-if="message.status">
              <div class="text-body-1 font-weight-bold">
                {{ message.product }}
              </div>
              <div>
                Ürününden <strong> {{ message.count }} {{ message.birim }}</strong>
                <v-chip :color="message.color" label class="ml-1" small> {{ message.plant }} Fabrikasından </v-chip>
                sepetinize eklendi
              </div>
            </div>
            <div v-else>
              <div class="text-body-1 font-weight-bold">
                {{ message.product }}
              </div>
              <div>
                <strong> Sepetten çıkartıldı</strong>
              </div>
            </div>
          </template>
          <template v-slot:action="{ close }">
            <v-btn icon small @click="close()">
              <v-icon small>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
        </v-snackbars>

        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />

      <!--   <v-card
          tile
          :flat="$vuetify.breakpoint.mdAndDown"
          :class="$vuetify.breakpoint.mdAndDown ? '' : ' rounded-tl-lg'"
          class="overflow-hidden"
        >
          <v-sheet
            v-if="$vuetify.breakpoint.xsOnly"
            :class="userData.role === 'PLSYR' ? 'search-mobil-plasiyer' : 'search-mobil'"
            color="transparent"
          >
            <v-text-field
              v-model="search"
              background-color="nightDark"
              solo
              :prepend-inner-icon="icons.mdiMagnify"
              label=""
              clearable
              hide-details
              class="rounded-0"
            >
              <template v-slot:label>
                <div class="text-caption">Aradığınız ürünü yazın</div>
              </template>
            </v-text-field>
          </v-sheet> -->

          <!-- Product Group -->
          <v-toolbar
          color="transparent"
          :height="$vuetify.breakpoint.smAndDown ? 120 : 87"
          :class="$vuetify.breakpoint.smAndDown ? 'satirVar' : null"      
        >
          <v-toolbar-title>
            <div class="d-flex align-center" :class="$vuetify.breakpoint.smAndDown ? 'pb-3' : null">
              <v-icon :large="!$vuetify.breakpoint.smAndDown" left>
                {{ icons[titleIcon] }}
              </v-icon>
              <span class="text-body-2 text-md-h6 font-weight-bold">
                {{ title }}
              </span>
            </div>
          </v-toolbar-title>
          
          <v-spacer v-if="$vuetify.breakpoint.smAndUp"></v-spacer>
          <div class="d-flex" :style="!$vuetify.breakpoint.smAndUp ? 'width:100%' : null">
        
              <v-text-field
                v-model="tableSearchProduct"
                label="Ürün Kodu Arama"
                rounded
                class="rounded-lg mr-3"
                placeholder="Ürün Kodunu veya Adını Yazabilirsiniz"
                persistent-placeholder
                hide-details
                filled
                clearable
                background-color="grey"
                @change="changeCategorie"
                @click:clear="tableSearchProductClear"
              >
              </v-text-field>
              <v-autocomplete
              v-if="!allProductCategories && $vuetify.breakpoint.smAndUp"
              v-model="productCategorie"
              label="Kategori"
              style="max-width: 300px"
              :items="productCategories"
              hide-details
              rounded
              class="rounded-lg mr-3"
              filled
              :clearable="productCategorie === 1 ? false : true"
              background-color="grey"
              @change="changeCategorie"
              @click:clear="productCategorieClear"
            ></v-autocomplete>

            <v-btn
              color="chip"
              depressed
              class="text-capitalize"
              style="height: 56px !important; min-width: 64px !important; padding: 0px !important"
              @click="allProductCategories = !allProductCategories"
            >
              <span v-if="!allProductCategories"
                ><v-icon>
                  {{ icons.mdiEye }}
                </v-icon>
              </span>
              <span v-else>
                <v-icon>
                  {{ icons.mdiEyeOff }}
                </v-icon>
              </span>
            </v-btn>
          </div>
        </v-toolbar>

        <v-tabs
            v-if="allProductCategories"
            vertical
            :class="allProductCategories ? 'siparis-tab siparis-hepsi' : 'siparis-tab'"
            :background-color="$vuetify.breakpoint.mdAndDown ? '#28243d' : null"
            style="box-shadow: none !important"
            show-arrows
            :height="allProductCategories ? null : 36"
            center-active
            :slider-size="3"
          >
            <v-tab
              v-for="(categorie, i) in productCategories"
              :key="categorie.id + '' + i"
              :style="allProductCategories ? 'height: 42px' : null"
              @click="tabChange(categorie.value)"
            >
              {{ categorie.text }}
            </v-tab>
          </v-tabs>

      <v-footer 
      v-if="footerType !== 'hidden'"
            app
            inset
            padless
            :class="{ 'mx-auto': appContentWidth !== 'full' }"
            :color="footerType === 'static' ?  'nightDark'  : null"
      >
          <div :class="{ 'px-5': footerType === 'fixed' }" class="py-4 w-full">      
            <v-item v-slot="{ active }">    
            <v-card
                name="footer"   
                :color="active ? 'secondary' : 'nightDark'"
                class="d-flex align-center justify-center"
                dark
                height="30"                          
              >
            
                <div class="text-body-1" :class="active ? 'font-weight-medium white--text' : 'black--text'">
               
                  <v-icon medium  :color=" 'error'">
                    {{ icons.mdiPaletteAdvanced }}
                  </v-icon>
              
                  <span  style="color: #cc3d41; " class="text-center " >
                    
                
                    Palet Sayısı:  {{ dipiskontoItems}}  palet</span>
                </div>
            </v-card>
            </v-item>
          </div>
      </v-footer>

          <!-- table -->
          <v-sheet :style="$vuetify.breakpoint.xsOnly ? 'padding-top:50px' : null" >
            <v-data-table
              :headers="tableColumns"
              :items="filteredData"
              :search="search"
              :items-per-page="250"
              fixed-header
              :height="dataTableHeight"
              :server-items-length="totalDataListTable"
              :loading="loading"
              :footer-props="{'items-per-page-options':[15, 30, 50, 100 , 250 , -1]}"
              class="my-component"
            >

            <template v-slot:body.prepend>
              <tr style="zoom: 0.8">

                <td class="pa-0">
                  <v-text-field
                    disabled
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  ></v-text-field>
                </td>

                <td class="pa-0">
                  <v-text-field
                    v-model="codeSearch"
                    :prepend-inner-icon="icons.mdiMagnify"
                    persistent-placeholder               
                    placeholder="Ara"
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                    attach
                    ></v-text-field>
                </td>
               
                <td class="pa-0">
                  <v-text-field
                    v-model="nameSearch"
                    :prepend-inner-icon="icons.mdiMagnify"
                    persistent-placeholder               
                    placeholder="Ara"
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                    attach
                    ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    disabled
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    disabled
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    disabled
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    disabled
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    disabled
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    disabled
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    disabled
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    disabled
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  ></v-text-field>
                </td>
                <td class="pa-0">
                  <v-text-field
                    disabled
                    filled
                    class="rounded-0 elevation-0"
                    hide-details
                  ></v-text-field>
                </td>
              </tr>
            </template>           
         
              <template #[`item.fiyat`]="{ item }">
                <span class="font-weight-bold"> {{ item.product_price_01 | currency }}</span>
              </template>            

              <template v-slot:header.birim_ritim="{ header }">
                <div style="width: 75px">Koli İçi Adet</div>
              </template>

              <template #[`item.adet_fiyat`]="{ item }">
                <span class="font-weight-bold"> {{ item.quantity_price | currency }}</span>
              </template>

              <template #[`item.bayi_iskonto`]="{ item }">
                <span class="font-weight-bold"> {{ item.custDiscounts }}</span>
              </template>

              <template #[`item.isk1`]="{ item }">
                <span class="font-weight"> {{ item.isk1 }}</span>
              </template>
              <template #[`item.isk2`]="{ item }">
                <span class="font-weight"> {{ item.isk2 }}</span>
              </template>

              <template #[`item.isk3`]="{ item }">
                <span class="font-weight"> {{ item.isk3 }}</span>
              </template>

              <template #[`item.isk4`]="{ item }">
                <span class="font-weight"> {{ item.isk4 }}</span>
              </template>


           
              <template #[`item.product_code`]="{ item }">
                <v-chip small label color="chip">
                  <span> {{ item.product_code }}</span>
                </v-chip>
              </template>

              <template #[`item.pictures`]="{ item }">
                <v-tooltip v-if="item.pictures" right color="white">
                  <template v-slot:activator="{ on, attrs }">
                    <v-card
                      v-bind="attrs"
                      tile
                      class="rounded d-flex align-center justify-center"
                      :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                      :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                      :color="item.pictures ? 'white' : 'primary'"
                      v-on="on"
                    >
                      <v-img
                        v-if="item.pictures"
                        :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                        :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                        :src="'https://portalapi.tukas.com.tr/' + item.pictures"
                      ></v-img>
                      <v-icon v-else>
                        {{ icons.mdiCameraOff }}
                      </v-icon>
                    </v-card>
                  </template>
                  <v-img width="250" height="250" :src="'https://portalapi.tukas.com.tr/' + item.pictures"></v-img>
                </v-tooltip>
                <v-card
                  v-else
                  tile
                  class="rounded d-flex align-center justify-center"
                  :width="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                  :height="!$vuetify.breakpoint.xsOnly ? 40 : 60"
                  :color="item.pictures ? 'white' : 'primary'"
                >
                  <v-img
                    v-if="item.pictures"
                    :width="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                    :height="!$vuetify.breakpoint.xsOnly ? 30 : 50"
                    :src="'https://portalapi.tukas.com.tr/' + item.pictures"
                  ></v-img>
                  <v-icon v-else>
                    {{ icons.mdiCameraOff }}
                  </v-icon>
                </v-card>
              </template>

              <template #[`item.miktar`]="{ item }">
                <Counter
                  :uid="item.id"
                  :birim="item.birim"
                  :birimRitim="item.birim_ritim"
                  :miktar="item.product_pic_01"
                  plant-id="01"
                  bg-color="transparent elevation-0"
                  button-color="white"
                  :dark="true"
                  :light="false"
                  :status="false"
                  :filled="false"
                  :solo="true"
                  :item="item"
                ></Counter>
              </template>
            </v-data-table>
          </v-sheet>
     <!--    </v-card> -->
      </div>

      <div v-else>
        <customer-list v-if="userData.role === 'PLSYR' && $vuetify.breakpoint.xsOnly" />
        <v-alert
          v-if="!$store.state.plasiyerCustomer"
          text
          outlined
          class="mx-3 mx-lg-0 rounded-pill mt-2 mt-md-0 text-caption"
          type="warning"
        >
          Lütfen yukarıdan bir müşteri seçin
        </v-alert>
      </div>
   
    </v-col>
  </v-row>



</template>

<script>
import Counter from '@/components/Counter.vue'
import CustomerList from '@/components/CustomerList.vue'
import store from '@/store'
import { useRouter } from '@core/utils'
import useAppConfig from '@core/@app-config/useAppConfig'

import {
  mdiArrowLeft,
  mdiCamera,
  mdiCameraOff,
  mdiClose,
  mdiDelete,
  mdiEye,
  mdiEyeOff,
  mdiFilter,
  mdiFilterOff,
  mdiFlash,
  mdiMagnify,
  mdiPlusThick,
  mdiSquareEditOutline,
  mdiPaletteAdvanced
} from '@mdi/js'
import { onMounted, ref, watch,computed } from '@vue/composition-api'
import VSnackbars from 'v-snackbars'
import Vue from 'vue'
import orderStoreModule from '../orderStoreModule'

const userData = JSON.parse(localStorage.getItem('userData'))

export default {
  components: {
    Counter,
    VSnackbars,
    CustomerList,
  },
  setup() {
    const dipiskontoItems = ref([])
    const {     
      footerType,
      appContentWidth,
    } = useAppConfig()

    const ORDER_APP_STORE_MODULE_NAME = 'app-order'

    const isDialogVisible = ref(false)

    const {route, router } = useRouter()
    // Register module
    if (!store.hasModule(ORDER_APP_STORE_MODULE_NAME)) {
      store.registerModule(ORDER_APP_STORE_MODULE_NAME, orderStoreModule)
    }

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(ORDER_APP_STORE_MODULE_NAME)) store.unregisterModule(ORDER_APP_STORE_MODULE_NAME)
    // })
    const dataListTable = ref([])
    const dataItem = ref({})
    const snackbar = ref([])
    const search = ref('')
    const product_title = ref('')
    const product_code = ref('')
    const status = ref(false)
    const koliAdet = ref('')
    const anlıkdeger = ref([])
    const toplam = ref(0)
    const tableSearch = ref('')
    const kodSearch = ref('')
    const koliEkle = () => {
      toplam.value = koliAdet.value * anlıkdeger.value.birim_ritim
      const uid1 = anlıkdeger.value.id

      store.commit('KOLI_TOPLAM', koliAdet.value)
      store.commit('KOLI_UID', uid1)
      store.commit('KOLI_EKLENDI', true)

      isDialogVisible.value = false
    }

    const openEkleDialog = item => {
      console.log(item.id)
      isDialogVisible.value = true
      anlıkdeger.value = item
    }

      //*********** Products***********************
    const productSearch = ref('')
    const allProductCategories = ref(false)
    const productCategories = ref([])
    const productCategorie = ref(1)
    const tableSearchProduct = ref('')
    const activeProductCategorie = ref(1)
    const fetchCategories = val => {
      
      productCategories.value = []
      const id = val
      const page = 1
      const count = 6
      const plant = '01'
      const method = 'getProductsByCtgId'
      store
        .dispatch('postMethod', {
          method,
          id,
          page,
          count,
          plant,
        })
        .then(response => {
          if (response.error === true) {
            return
          }
          productCategories.value.push({
            value: val,
            text: 'Hepsi',
          })
          for (const [key, value] of Object.entries(response.detail.categories)) {
            //if (value.ust == val) {
              productCategories.value.push({
                value: value.id,
                text: value.kategori,
              })
            //}
          }
        })
    }
    const productCategorieClear = () => {
      setTimeout(() => {
        productCategorie.value =  1 
      }, 100)
    }

    const tableSearchProductClear = () => {
      setTimeout(() => {
        tableSearchProduct.value =  "" 
      }, 100)
    }
    

    const changeCategorie = () => {
      
      if(activeProductCategorie.value!="1" && allProductCategories.value=== true ){
        fetchProducts(activeProductCategorie.value,tableSearchProduct.value)
      }
      else{

        fetchProducts(productCategorie.value,tableSearchProduct.value)
      }
    }
    const fetchProducts = (type  ,type2 ) => {
  
      const id = type 
      const page = 1
      const count = 6
      const plant = '01'
      const prdName = type2
      const method = 'getProductsByCtgId'
      store
        .dispatch('postMethod', {
          method,
          id,
          page,
          count,
          plant,
          prdName
          
        })
        .then(response => {
          if (response.error) {
            Vue.swal({
              title: 'Başarısız',
              text: response.msg,
              width: '350px',
              icon: 'error',
              background: '#FF4C51',
              showCloseButton: false,
              showConfirmButton: true,
              confirmButtonColor: '#cc3d41',
            })

            router.push({ name: 'dashboard' })

            return
          }

          const productList = response.detail.products.map(item => {
  
            const items = {}
            items.id = item.id
            items.product_code = item.urun_kodu
            items.mattype = item.mattype
            items.product_title = item.baslik
            items.birim_ritim = Number(item.birim_ritmi)
            items.pictures = item.resim
            items.product_01 = true
            items.product_02 = true
            items.priceAll = item.priceAll
            items.kategori = item.kategori
            items.birim = item.birim
            items.custDiscounts=item.custDiscounts
            
            const price_01 = item.priceAll.find(i => i.plant === '01')   
            
            const priceArray = Object.entries(price_01.priceListDiscs).map(([key, value]) => ({
  key: key,
  value: value,
}));
            for (let i = 0; i < priceArray.length; i++) {
                if (priceArray[i] !== undefined) {
                  items[`isk${priceArray[i].key}`] =  `%${priceArray[i].value}`;
                }
            }
            if (item.priceAll.length > 0) {
                const price_01 = item.priceAll.find(i => i.plant === '01')
                const price_02 = item.priceAll.find(i => i.plant === '02')
                if (price_01) {
                  items.product_price_01 = price_01?.grossprice//   price1                
                }
                if (price_02) {
                  items.product_price_02 = price_02?.grossprice// price1
                }              
                //items.quantity_price = (price_01?.price1)/ (Number(item.birim_ritmi))
                items.quantity_price = (price_01?.grossprice)/ (Number(item.birim_ritmi))

               /*  if(item.priceAll[0]!=='undefined')
                    items.isk1 =  item.priceAll[0]?.priceListDiscsStr[0]    
                if(item.priceAll[1]!=='undefined')
                    items.isk2 =  item.priceAll[1]?.priceListDiscsStr[0] 
                if(item.priceAll[2]!=='undefined')
                   items.isk3 =  item.priceAll[2]?.priceListDiscsStr[0]
                if(item.priceAll[3]!=='undefined')
                    items.isk4 =  item.priceAll[3]?.priceListDiscsStr[0]  */
            }
            
            return items
          })
          dataListTable.value = productList
          //pageLoading.value = false
          loading.value = false
          fetchBasket()
        })
        .catch(e => {
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }
    watch(
      () => allProductCategories.value,
      () => {        
        if ( allProductCategories.value == false) {
          activeProductCategorie.value=0
          titleFn()
        }
      },
    )
    //******************


    watch(
      () => store.state.openKoli,
      () => {
        if (store.state.openKoli == true) {
          isDialogVisible.value = true
        } else {
          isDialogVisible.value = false
        }
      },
    )

    const tableColumns = computed(() => [
      {
        text: 'Resim',
        value: 'pictures',
        width: '40px',
        sortable: false,
      },
      {
        text: 'Kod',
        value: 'product_code',
        width: '150px',
        sortable: false,
       
        /* filter: value => {
           
          if (!product_code.value) return true

          return value.toString().toLocaleUpperCase('TR').indexOf(kodSearch.value.toLocaleUpperCase('TR')) !== -1
        }, */
      },
   /*    {
        text: 'İskonto',
        value: 'discount',
        width: '140px',
        sortable: false,
      },     */
      {
        
        text: 'Ürün Adı',
        value: 'product_title',
        sortable: false,
        class: 'min-width-400',
      /*   filter: value => {
          
          if (!product_title.value) return true

          return value.toString().toLocaleUpperCase('TR').indexOf(product_title.value.toLocaleUpperCase('TR')) !== -1
        }, */
      },
      {
        text: 'Koli İçi Adet',
        value: 'birim_ritim',
        width: '60px',
        sortable: false,
        align: 'center',
      },

      {
        text: 'Adet Fiyat',
        value: 'adet_fiyat',
        align: 'center',
        width: '150px',
        sortable: false,
      }, 
      {
        text: 'Koli Fiyat',
        value: 'fiyat',
        align: 'center',
        width: '150px',
        sortable: false,
      },
      {
        text: 'Bayi İskonto',
        value: 'bayi_iskonto',
        width: '60px',
        align: 'center',
        sortable: false,
      },

      {
        text: 'İsk-1',
        value: 'isk1',
        width: '65px',
        align: 'center',
        sortable: false,
      },
      {
        text: 'İsk-2',
        value: 'isk2',
        width: '65px',
        align: 'center',
        sortable: false,
      },
      {
        text: 'İsk-3',
        value: 'isk3',
        width: '65px',
        align: 'center',
        sortable: false,
      },
      {
        text: 'İsk-4',
        value: 'isk4',
        width: '65px',
        align: 'center',
        sortable: false,
      },

      {
        text: 'Miktar',
        value: 'miktar',
        width: '150px',
        align: 'center',
        sortable: false,
      },
      
      ])
  
    if (window.screen.availWidth <= 600) {
      tableColumns.forEach(item => {
        delete item.filter
      })
    }
    const statusCustomer = ref(false)

    onMounted(() => {
      if (store.state.secondState == true && store.state.secondMsg) {
        Vue.swal({
          title: 'Uyarı',
          html: store.state.secondMsg,
          icon: 'warning',
          background: '#FFB400',
          timerProgressBar: true,
          showConfirmButton: false,
        })
        router.push({ name: 'payment' })
      }

      if (userData.role === 'PLSYR' && store.state.plasiyerCustomer === null) {
        status.value = false
      } else {
        status.value = true
        loading.value = true
        statusCustomer.value = true
        fetchBasket()
        fetchDatas()
      }

      // if (userData.role == 'PLSYR' && userData.uname != 'plasiyer') {
      //   router.push({ name: 'dashboard' })
      // }
    })

    watch(
      () => store.state.plasiyerCustomer,
      () => {
        console.log('x = ' + store.state.secondState)
        if (store.state.secondState == true) {
          Vue.swal({
            title: 'Uyarı',
            text: 'Kullanıcı değişti. Sadece ödeme yapılabilir',
            width: '350px',
            icon: 'warning',
            background: '#FFB400',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
          dataListTable.value = []
        }
        loading.value = true
        statusCustomer.value = false
        fetchBasket()
        fetchDatas()
        status.value = true
        //fetchDatas()
        // if (userData.role == 'PLSYR' && userData.uname != 'plasiyer') {
        //   router.push({ name: 'dashboard' })
        // }
      },
    )

    const totalDataListTable = ref(0)
    const loading = ref(false)
    const options = ref({
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dataTotalLocal = ref([])
    const count = ref(1)

    /*
    watch(
      () => store.getters['app-order/doneBasket'],
      () => {
        fetchDatas(store.getters['app-order/doneBasket'])

        // fetchBasket()
        // refreshBasket()
      },
    )
    */

    const fetchBasket = async () => {
      await store.dispatch('app-order/fetchActiveBasketList').then(response => {
        if (!response.error) {          
          if(response.toplamlar!==undefined)
            dipiskontoItems.value= roundToDecimal(response.toplamlar.toplamPaletQty, 2) 
            //dipiskontoItems.value=response.toplamlar.toplamPaletQty

        } else {
          // console.table('hatalı =>', response)
        }
      })
    }

    const roundToDecimal = (value, decimals) => {
  const factor = Math.pow(10, decimals);
  return Math.round(value * factor) / factor;
}

    const fetchDatas = async basketList => {
      
      const id = 0
      const page = 1
      const count = 6
      const plant = '01'
      const method = 'getProductsByCtgId'
      const delivery_date = store.state['app-order'].deliveryDate
      await store
        .dispatch('postMethod', {
          method,
          id,
          page,
          count,
          plant,
          delivery_date,
        })
        .then(response => {
       
          const productList = response.detail.products.map(item => {
            
            const items = {}
            items.id = item.id
            items.product_code = item.urun_kodu
            items.mattype = item.mattype
            //items.discount= item.priceAll[0].priceListDiscsStr           
            items.product_title = item.baslik
            items.birim_ritim = Number(item.birim_ritmi)
            items.pictures = item.resim
            items.product_01 = true
            items.product_02 = true
            items.priceAll = item.priceAlls
            items.birim = item.birim
            items.custDiscounts=item.custDiscounts

            const price_01 = item.priceAll.find(i => i.plant === '01')  
            
            
            const priceArray = Object.entries(price_01.priceListDiscs).map(([key, value]) => ({
  key: key,
  value: value,
}));
            for (let i = 0; i < priceArray.length; i++) {
                if (priceArray[i] !== undefined) {
                  items[`isk${priceArray[i].key}`] = `%${priceArray[i].value}`;
                }
            }


            if (item.priceAll.length > 0) {
              const price_01 = item.priceAll.find(i => i.plant === '01')
              const price_02 = item.priceAll.find(i => i.plant === '02')
              if (price_01) {
                items.product_price_01 = price_01?.grossprice//price1
              }
              if (price_02) {
                items.product_price_02 = price_02?.grossprice//price1
              }              
              items.quantity_price = (price_01?.grossprice)/ (Number(item.birim_ritmi))
            
               /*  if(item.priceAll[0]!=='undefined'){
                    items.isk1 =  item.priceAll[0]?.priceListDiscsStr[0]                   
                }

                 if(item.priceAll[1]!=='undefined')
                    items.isk2 =  item.priceAll[1]?.priceListDiscsStr[0] 
                if(item.priceAll[2]!=='undefined')
                   items.isk3 =  item.priceAll[2]?.priceListDiscsStr[0]
                if(item.priceAll[3]!=='undefined')
                    items.isk4 =  item.priceAll[3]?.priceListDiscsStr[0]  */
            }
            if (typeof items.product_price_01 === 'undefined') {
              items.product_01 = false
            }
            if (typeof items.product_price_02 === 'undefined') {
              items.product_02 = false
            }

            return items
          })

          dataListTable.value = productList
          console.log(dataListTable)
          totalDataListTable.value = Number(response.detail.toplam)
          loading.value = false          
        /*   if (store.state.secondState == true) {
            dataListTable.value = []
          } */

         if (userData.role === 'PLSYR' && store.state.secondState == true) {            
            dataListTable.value = []
          }
          
        })
        .catch(e => {
          loading.value = false
          Vue.swal({
            title: 'Hata',
            text: e.message,
            width: '350px',
            icon: 'error',
            background: '#FF4C51',
            showCloseButton: false,
            showConfirmButton: true,
            confirmButtonColor: '#cc3d41',
          })
        })
    }
    const statusFind = (arr, value, select) => store.state.tanimlar[arr].find(item => item.value === value)[select]



     // Tab
     const tabChange = val => {
      
     
      activeProductCategorie.value=val
      fetchProducts(val, tableSearchProduct.value)
    }

    // Title
    watch(
      () => route.value.params.type,
      () => {
        
        titleFn()
      },
    )
    onMounted(() => {      
      titleFn()
    })
    const title = ref('')
    const titleIcon = ref('')

    const titleFn = () => {      
      dataListTable.value = []
      productCategories.value = []
      loading.value = true
      tableSearch.value = ''
    
      title.value = 'SİPARİŞ'
      titleIcon.value = 'mdiFlash'
      productCategorie.value = 0
      fetchCategories(0)
      fetchProducts(0, tableSearchProduct.value)
     
    }

    return {
      statusFind,
      userData,
      dataItem,
      dataListTable,
      tableColumns,
      koliAdet,
      totalDataListTable,
      loading,
      options,
      dataTotalLocal,
      koliEkle,
      toplam,
      fetchDatas,
      count,
      snackbar,
      search,
      product_title,
      product_code,
      openEkleDialog,
      status,
      statusCustomer,
      isDialogVisible,
      anlıkdeger,
      tableSearch,
      kodSearch,
      // Products
      fetchProducts,
      productCategories,
      productCategorie,
      productCategorieClear,
      tableSearchProductClear,
      changeCategorie,
      allProductCategories,
      productSearch,
      tableSearchProduct,
      activeProductCategorie,
       // Tab
       tabChange,
       // Title
       title,
      titleIcon,
      // icons
      footerType,
      appContentWidth,
      dipiskontoItems,
      icons: {
        mdiCamera,
        mdiDelete,
        mdiCameraOff,
        mdiFlash,
        mdiEye,
        mdiEyeOff,
        mdiFilter,
        mdiFilterOff,
        mdiPlusThick,
        mdiSquareEditOutline,
        mdiClose,
        mdiMagnify,
        mdiArrowLeft,
        mdiPaletteAdvanced
      },
    }
  },
  data() {
    return {
      nameSearch: '',
      codeSearch:'',
      panel: null,
      date: null,
      modal: false,
      filterStatus: false,
      dataTableHeight: '',
      height: {
        system: 0,
        top: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
        footer: this.$vuetify.breakpoint.smAndDown ? 0 : 0,
      },
    }
  },

  watch: {
    panel(val) {
      if (val === 0) {
        setTimeout(() => {
          this.height.top = 250
          this.onResize()
        }, 300)
      } else {
        this.height.top = 48
        this.onResize()
      }
    },
  },


  computed: {

filteredData() {  
  let conditions = [];  
  if (this.nameSearch) {
    conditions.push(this.filterName);
  }
  if (this.codeSearch) {
    conditions.push(this.filterCode);
  }
  
  if (conditions.length > 0) {
    return this.dataListTable.filter((data) => {
      return conditions.every((condition) => {
        return condition(data);
      })
    })
  }
  
  return this.dataListTable;
}
},

  mounted() {
    this.$nextTick(() => {
      this.onResize()
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {

    filterName(item) {
          return item.product_title.toLowerCase().includes(this.nameSearch.toLowerCase())
      },
      filterCode(item) {
          return item.product_code.toLowerCase().includes(this.codeSearch.toLowerCase())
      },


    addBasket(plant, product, id, count, birim) {
      store
        .dispatch('app-order/fetchAddToBasket', {
          method: 'addToBasket',
          uid: id,
          qty: count,
          plant,
        })
        .then(response => {
          if (response.error) {
            Vue.swal({
              title: 'Hata',
              html: response.detail.msg,
              icon: 'error',
              timer: 2000,
              background: '#FF4C51',

              timerProgressBar: true,
              showConfirmButton: false,
            })
          } else {
            this.snackbar.push({ product, count, birim })
          }
        })
    },
    async addToBasket(plants, product, id, count, birim) {
      if (plants.length === 1) {
        this.addBasket(plants[0].plant, product, id, count, birim)

        return
      }

      const plantsMap = new Map(plants.map(object => [object.plant, object.stext]))

      const { value: plant } = await Vue.swal({
        icon: 'info',
        title: 'Fabrika Seçimi',
        text: 'Lütfen ürünü eklemek istediğiniz fabrikayı seçiniz',
        input: 'select',
        inputOptions: plantsMap,
      })

      if (plant) {
        this.addBasket(plant, product, id, count, birim)
      }
    },
    onResize() {
      const val = this.$vuetify.breakpoint.xsOnly ? 120 : 90
      this.dataTableHeight = window.innerHeight - val - this.height.top - this.height.footer - 1
    },
  },
}
</script>

<style lang="scss">
@import '~@core/layouts/styles/_variables';
.search-mobil {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 70px;
}

.search-mobil-plasiyer {
  position: fixed;
  z-index: 5;
  width: 100%;
  left: 0;
  top: 135px !important;
}
.text-plain {
  font-size: 10px;
  line-height: 1;
  padding-bottom: 2px;
}
.po-0 {
  .v-toolbar__content {
    padding: 0px 18px 0px 15px !important;
  }
}
// .v-data-table__wrapper::-webkit-scrollbar {
//   width: 8px;
//   height: 8px;
//   background-color: #3b355a;
// }
// .v-data-table__wrapper::-webkit-scrollbar-thumb {
//   background-color: #0083e8;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_konya {
//   border-bottom: 1px solid #ffb400 !important ;
// }
// .v-application.theme--dark .app-content-container .v-data-table-header .plant_tekirdag {
//   border-bottom: 1px solid #0083e8 !important ;
// }

.min-width-400 {
  min-width: 360px;
}
.style-1 {
  background-color: rgba(255, 255, 255, 0.05);
}
.style-2 {
  // background-color: rgb(114, 114, 67);
}
/*

@media #{map-get($display-breakpoints, 'xs-only')} {
  .v-data-table > .v-data-table__wrapper .v-data-table__mobile-row {
    min-height: auto !important;
  }
  .theme--light tr {
    background-color: #fff !important;
  }
  .theme--dark tr {
    background-color: #3b355a !important;
  }
  .v-data-table__empty-wrapper {
    td {
      position: relative !important;
    }
  }
  tr {
    position: relative;
    padding: 15px 0;

    border-bottom: thin solid rgba(231, 227, 252, 0.14);

    td:nth-child(1) {
      position: absolute;

      .v-data-table__mobile-row__header {
        display: none;
      }
    }
    td:nth-child(2),
    td:nth-child(3) {
      padding-top: 5px !important;
      .v-data-table__mobile-row__header {
        display: none;
      }
      .v-data-table__mobile-row__cell {
        padding-left: 70px;
        text-align: left !important;
      }
    }
    td:nth-child(4) {
      display: flex;
      justify-content: flex-end;
      padding-top: 30px !important;
      .v-data-table__mobile-row__header {
        display: none;
      }
    }
    td:nth-child(5) {
      display: flex;
      justify-content: flex-end;
      .v-data-table__mobile-row__header {
        display: none;
      }
    }
  }
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > td:last-child,
  .theme--dark.v-data-table > .v-data-table__wrapper > table > tbody > tr:not(:last-child) > th:last-child {
    border: none !important;
  }
}
*/
.v-tooltip__content {
  opacity: 1 !important;
  box-shadow: 5px 5px 10px 0px rgb(0 0 0 / 30%) !important;
}

.rounded-kor {
  .v-input__slot {
    padding: 0 !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
  }
}



.siparis-tab .v-tabs-slider-wrapper {
  display: none;
}

.siparis-tab .v-tab {
  font-size: 11px !important;
  color: white !important;
  border-radius: 50px;
  // border-radius: 0px;
  // background-color: #3b3854 !important;
  background-color: #ff151a !important;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
    rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
    rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;

  //box-shadow: -5px -5px 9px rgba(255,255,255,0.45), 5px 5px 9px rgba(94,104,121,0.3);
  
       
  /*box-shadow: 0px 1px 2px 0px rgba(0,255,255,0.7),
            1px 2px 4px 0px rgba(0,255,255,0.7),
            2px 4px 8px 0px rgba(0,255,255,0.7),
            2px 4px 16px 0px rgba(0,255,255,0.7);
            
  */
  //box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, 
  //rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset,
   // rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, 
   // rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px,
   // rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, 
   // rgba(0, 0, 0, 0.09) 0px 32px 16px;


}
.siparis-tab .v-tab--active { 
   background-color: #0d6efd !important;

}
.siparis-hepsi {
  .v-tab {
    background-color: #dfdfdf;
  }
  .v-slide-group__content {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    padding: 18px;
  }
}
@media screen and (max-width: 599px) {
  .siparis-tab .v-tab {
    font-size: 11px !important;
    color: white !important;
    border-radius: 0px !important;
    background-color: #ff151a !important;
    box-shadow: none !important;
  }
  .siparis-tab .v-tab--active {
    background-color: #0d6efd !important;
  }
  
  .siparis-hepsi {
    .v-tab {
      background-color: #dfdfdf;
    }
    .v-slide-group__content {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-column-gap: 1px;
      grid-row-gap: 1px;
      padding: 0px;
    }
  }
}
.satirVar {
  .v-toolbar__content {
    flex-direction: column;
    margin: 10px 0;
  }
}
.my-component {
  margin-bottom: 80px;/* v-data-table bileşeninin diğer öğelerin üzerinde olmasını sağlar */
}

</style>
